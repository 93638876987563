import React, { useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Layout } from '../component/Layout'
import { PropertyList } from '../component/page_component/PropertyList'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { parseQueryString } from '../utils/helper'
import { DESTINATIONS } from '../utils/constants'
import DestinationHeader from '../component/page_component/DestinationHeader'
import { Helmet } from 'react-helmet'
import hreflangConfig from '../utils/hreflangConfig'
import countriesData from '../data/countriesData.json'
import departmentData from '../data/departmentData.json'
import SeoContent from '../component/page_component/SeoContent'
import Faq from '../component/page_component/faq'
import { SearchContext } from '../contexts/searchContext'
import ContactForm from '../component/page_component/contact/contactForm'
import Destination from '../component/page_component/Destination'
import PreFooterMenu from '../component/page_component/contact/preFooterMenu'
import { useLocation } from '@reach/router'

// Types for better type safety
interface CountryPageProps extends PageProps {
  pageContext: {
    data: any;
    country: string;
  };
}


const Country: React.FC<CountryPageProps> = ({ location, pageContext: { data, country } }) => {
  const intl = useIntl();
  const { setSearch, flushSearchState } = useContext(SearchContext);
  const loc = useLocation();

  // Get nested data from countriesData with type safety
  const getNestedData = (data: any, lang: string, key: string): any => {
    return data?.[lang]?.[key] || null;
  };

  // Get country-specific data
  const propsData = getNestedData(countriesData, intl.locale, country);
  
  // Get destination image or fallback to default
  const image = DESTINATIONS[country.toLowerCase() as keyof typeof DESTINATIONS]?.img || "default_main.jpg";

  // Extract content from propsData



  const faq = propsData?.content?.faq || [];
  const sections = propsData?.content?.sections || [];
  const title = propsData?.content?.title || '';
  const subtitle = propsData?.content?.description || '';



  // Get canonical URL from site metadata
  const { site: { siteMetadata: { siteUrl } } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const canonicalUrl = `${siteUrl}${location.pathname}`;



  // Get hreflang links for SEO
  const hreflangLinks = hreflangConfig[`/fr/${country}/` as keyof typeof hreflangConfig];

  // Effect for handling search state and country selection
  useEffect(() => {
    // Only flush search state if not explicitly prevented
    if (loc?.hash !== "#no-update") {
      flushSearchState();
    }

    // Set country in search context if available
    if (country) {
      const countryItem = {
        category: 'COUNTRY',
        value: country,
        count: 0,
        label: country,
        counts: 0,
        tags: [country]
      };
      setSearch({ name: 'country', value: [countryItem] });
    }

    // Set default transaction type to 'buy'
    setSearch({ name: 'transactionType', value: 'buy' });
  }, []);

  return (
    <Layout
      location={location}
      showFlotingMenu={false}
      title={`${title} - Kretz`}
    >
      {/* SEO Optimization */}
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks && Object.entries(hreflangLinks).map(([lang, href]) => (
          <link key={lang} rel="alternate" hrefLang={lang} href={href as string} />
        ))}
        <meta name="description" content="Destinations de Rêve - Kretz" />
      </Helmet>

      {/* Main Content */}
      <Grid container justifyContent="center" id="buy-region-dream-destination">
        <PropertyList
          filterBackgroundColor={'#fff'}
          data={data}
          location={location}
          title={title}
          description={subtitle}
          image={image}
          withFilter={true}
          disableCountryFilter={true}
          disableCityFilter={false}
        />
      </Grid>

      {/* Additional Sections */}
      <Destination pays={country} />
      <ContactForm
        title={intl.formatMessage({ id: 'new.Formulaire de contact' })}
        subtitle={intl.formatMessage({ id: 'new.Résultats de recherche_4' })}
        backgroundColor="#fff"
      />
      <SeoContent sections={sections} />
      <Faq items={faq} />
      <PreFooterMenu />
    </Layout>
  );
};

export default Country;
